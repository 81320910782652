.bg-image {
  background-image: url('images/IMG_9101_A.jpg');
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 8%;
  height: 1200px;
}

.btn-circle {
  width: 110px;
  height: 110px;
  text-align: center;
  padding: 0;
  font-size: 18px;
  line-height: 1.428571429;
  border-radius: 50%;
  margin-right: 25px;
}

.btn-green {
  background-color: #d6c9b0;
}

.btn-blue {
  background-color: #92bdeb;
}

.btn-red {
  background-color: #d81951;
}

.hello {
  font-family: 'Work Sans', sans-serif;
}
.home-hello-box {
  padding-left: 18px;
  text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.9);
}


.App {
  text-align: center;
}

@media (max-width: 991px) {
  .home-about-box {
    margin-top: 25px;
  }
  .bg-image {
    height: 100vh;
  }
}

@media (max-width: 640px) {
    .bg-image {
        background-position: 60% 10%;
        transform: scale(0.95);
    }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

